<template>
  <div class="mytasks_iframe_wrap" style="padding:10px;">
    <a-spin :spinning="loading" style="padding:10px;">
      <a-dropdown :trigger="['contextmenu']">
        <vue-draggable-resizable style="z-index:998">
          <div
            v-if="isZy"
            :contenteditable="!isZy"
            class="show"
            :style="
              `font-size:${fontSzie};` +
                `font-family:${fontFamily};` +
                `line-height:${lineHeight};` +
                `letter-spacing:${letterSpacing};` +
                `text-align:${textAlign};` +
                `font-style:${fontStyle};` +
                `font-weight:${fontWeight};` +
                `writing-mode:${fontMode};` +
                `text-orientation:${fontMode == 'horizontal-tb' ? 'initial' : 'upright'};` +
                `transform:scale(${scaleWidth},${scaleHeight});` +
                `-webkit-text-stroke:${storkeSize + 'px ' + storkeColor}`
            "
          >
            <div v-for="(item, idx) in zyText" :key="idx">
              <ruby>
                <template v-for="(i, index) in item">
                  {{ i.text }}
                  <rt
                    :key="index"
                    :style="`text-align: ${rtTextAlign};color: ${rtTextColor};font-size: ${rtTextSize};`"
                    >{{ i.zy }}</rt
                  >
                </template>
              </ruby>
              {{ idx == text.split('\n').length - 1 ? '' : '\n' }}
            </div>
          </div>
          <div
            :contenteditable="!isZy"
            @contextmenu="textClick"
            id="add-content"
            v-else
            class="show"
            :style="
              `font-size:${fontSzie};` +
                `font-family:${fontFamily};` +
                `line-height:${lineHeight};` +
                `letter-spacing:${letterSpacing};` +
                `text-align:${textAlign};` +
                `font-style:${fontStyle};` +
                `font-weight:${fontWeight};` +
                `writing-mode:${fontMode};` +
                `text-orientation:${fontMode == 'horizontal-tb' ? 'initial' : 'upright'};` +
                `transform:scale(${scaleWidth},${scaleHeight});` +
                `-webkit-text-stroke:${storkeSize + 'px ' + storkeColor}`
            "
          >
            <span v-if="bjTexts.length && bjTexts[0]">
              {{ text.substr(0, text.indexOf(bjTexts[0])) }}
              <span style='-webkit-text-emphasis: "●";-webkit-text-emphasis-position: under;'>{{ bjTexts[0] }}</span>
              {{ text.substr(text.indexOf(bjTexts[0]) + bjTexts[0].length) }}
            </span>
            <span v-else>{{ text }}</span>
          </div>
        </vue-draggable-resizable>

        <a-menu slot="overlay">
          <a-menu-item @click="bjClick">
            标记
          </a-menu-item>
          <a-menu-item @click="zyClick">
            注音
          </a-menu-item>
        </a-menu>
      </a-dropdown>

      <div style="padding:10px;line-height:60px">
        <!--         <span>
          文本内容
          <a-textarea v-model="text"></a-textarea>
        </span> -->
        <span>
          <a-radio-group v-model="fontMode">
            <a-radio value="horizontal-tb">
              横向
            </a-radio>
            <a-radio value="vertical-rl">
              竖向从右到左
            </a-radio>
            <a-radio value="vertical-lr">
              竖向从左到右
            </a-radio>
          </a-radio-group>
        </span>
        <span>
          字体
          <a-select v-model="fontId" style="width: 120px" @change="handleChange">
            <a-select-option v-for="item in fontList" :value="item.id" :key="item.id">
              {{ item.displayName }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          字号
          <a-select v-model="fontSzie" @change="sizeChange" style="width: 120px">
            <a-select-option v-for="item in fontSizes" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          行间距
          <a-select v-model="lineHeight" style="width: 120px">
            <a-select-option v-for="item in lineHeights" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          字间距
          <a-select v-model="letterSpacing" style="width: 120px">
            <a-select-option v-for="item in letterSpacings" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          字体宽度
          <a-select v-model="scaleWidth" style="width: 120px">
            <a-select-option v-for="item in scales" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          字体高度
          <a-select v-model="scaleHeight" style="width: 120px">
            <a-select-option v-for="item in scales" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          对齐方式
          <a-radio-group v-model="textAlign">
            <a-radio value="left">
              {{ fontMode == 'horizontal-tb' ? '左对齐' : '上对齐' }}
            </a-radio>
            <a-radio value="center">
              居中
            </a-radio>
            <a-radio value="right">
              {{ fontMode == 'horizontal-tb' ? '右对齐' : '下对齐' }}
            </a-radio>
          </a-radio-group>
        </span>
        <span>
          <a-checkbox @change="weightChange">
            加粗
          </a-checkbox>
          <a-checkbox @change="fontStyleChange">
            斜体
          </a-checkbox>
          <a-checkbox @change="zyChange" :checked="isZy">
            是否注音
          </a-checkbox>
        </span>
        <span v-if="isZy">
          注音对齐方式
          <a-radio-group v-model="rtTextAlign">
            <a-radio value="left">
              {{ fontMode == 'horizontal-tb' ? '左对齐' : '上对齐' }}
            </a-radio>
            <a-radio value="center">
              居中
            </a-radio>
            <a-radio value="right">
              {{ fontMode == 'horizontal-tb' ? '右对齐' : '下对齐' }}
            </a-radio>
          </a-radio-group>
        </span>
        <div>
          字体描边
          <colorPicker v-model="storkeColor" />
          <a-input-number :min="0" style="width:120px" v-model="storkeSize" />
        </div>
      </div>
    </a-spin>
    <a-modal class="question-modal" :visible="visible" :footer="null" :closable="false" @cancel="handleCancel">
      <a-input-search allowClear v-model="addZyText" @pressEnter="addZy" placeholder="回车保存" style="width: 100%" />
    </a-modal>
  </div>
</template>

<script>
import { batchStyleInsertHeader } from '@/utils/util'
import { getAction } from '@/api/manage'
export default {
  data() {
    return {
      loading: false,
      visible: false,
      storkeSize: 0,
      storkeColor: '#000000',
      text: '字体样式demo',
      textAlign: 'left',
      fontList: [],
      fontFamily: undefined,
      fontMode: 'horizontal-tb',
      fontId: undefined,
      fontSzie: '17px',
      bjTexts: [],
      rtTextSize: '6px',
      rtTextColor: '#000000',
      rtTextAlign: 'center',
      lineHeight: 1,
      scaleWidth: 1,
      scaleHeight: 1,
      letterSpacing: '0',
      scales: [
        {
          label: '0%',
          value: 0
        },
        {
          label: '10%',
          value: 0.1
        },
        {
          label: '20%',
          value: 0.2
        },
        {
          label: '30%',
          value: 0.3
        },
        {
          label: '40%',
          value: 0.4
        },
        {
          label: '50%',
          value: 0.5
        },
        {
          label: '60%',
          value: 0.6
        },
        {
          label: '70%',
          value: 0.7
        },
        {
          label: '80%',
          value: 0.8
        },
        {
          label: '90%',
          value: 0.9
        },
        {
          label: '100%',
          value: 1
        }
      ],
      letterSpacings: [
        {
          label: '0',
          value: '0'
        },
        {
          label: '10%',
          value: '1px'
        },
        {
          label: '20%',
          value: '2px'
        },
        {
          label: '30%',
          value: '3px'
        },
        {
          label: '40%',
          value: '4px'
        },
        {
          label: '50%',
          value: '5px'
        },
        {
          label: '60%',
          value: '6px'
        },
        {
          label: '70%',
          value: '7px'
        },
        {
          label: '80%',
          value: '8px'
        },
        {
          label: '90%',
          value: '9px'
        },
        {
          label: '100%',
          value: '10px'
        }
      ],
      lineHeights: [
        {
          label: '0',
          value: '0'
        },
        {
          label: '50%',
          value: '0.5'
        },
        {
          label: '60%',
          value: '0.6'
        },
        {
          label: '70%',
          value: '0.7'
        },
        {
          label: '80%',
          value: '0.8'
        },
        {
          label: '90%',
          value: '0.9'
        },
        {
          label: '100%',
          value: '1'
        },
        {
          label: '110%',
          value: '1.1'
        },
        {
          label: '115%',
          value: '1.15'
        },
        {
          label: '120%',
          value: '1.2'
        },
        {
          label: '130%',
          value: '1.3'
        },
        {
          label: '140%',
          value: '1.4'
        },
        {
          label: '150%',
          value: '1.5'
        }
      ],
      fontSizes: [
        {
          label: 4,
          value: '1px'
        },
        {
          label: 5,
          value: '5px'
        },
        {
          label: 6,
          value: '9px'
        },
        {
          label: 7,
          value: '13px'
        },
        {
          label: 7.5,
          value: '15px'
        },
        {
          label: 8,
          value: '17px'
        },
        {
          label: 8.5,
          value: '19px'
        },
        {
          label: 9,
          value: '21px'
        },
        {
          label: 9.5,
          value: '23px'
        },
        {
          label: 10,
          value: '25px'
        },
        {
          label: 11,
          value: '29px'
        },
        {
          label: 12,
          value: '33px'
        },
        {
          label: 14,
          value: '41px'
        },
        {
          label: 18,
          value: '57px'
        },
        {
          label: 24,
          value: '81px'
        },
        {
          label: 30,
          value: '105px'
        },
        {
          label: 36,
          value: '129px'
        },
        {
          label: 48,
          value: '177px'
        },
        {
          label: 60,
          value: '225px'
        },
        {
          label: 72,
          value: '273px'
        }
      ],
      fontWeight: 400,
      fontStyle: 'normal',
      SelectionText: '',
      isZy: false,
      zyText: [],
      addZyText: ''
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    /* window.getSelection().toString() */
    var content = document.getElementById('add-content')
    content.addEventListener('blur', e => {
      /* this.text = e.target.innerText */
      this.zyText = e.target.innerText.split('\n').map(item => {
        return item.split('').map(i => {
          return {
            text: i,
            zy: ''
          }
        })
      })
      console.log(this.zyText)
      this.$forceUpdate()
    })
  },
  methods: {
    textClick(e) {
      e.preventDefault()
      this.bjTexts = []
      this.SelectionText = window.getSelection().toString()
    },
    zyClick() {
      if (this.SelectionText && this.SelectionText.length > 1) {
        this.$message.error('注音字符只能选中一个')
        return
      }
      this.visible = true
      /*       this.zyText.forEach(item=>{
        item.forEach(i=>{
            if(i.text==this.SelectionText){
                i.zy=this.SelectionText
            }
        })
      }) */
    },
    addZy() {
      this.zyText.forEach(item => {
        item.forEach(i => {
          if (i.text == this.SelectionText) {
            i.zy = this.addZyText
          }
        })
      })
      this.SelectionText = ''
      this.visible = false
      this.isZy = true
    },
    bjClick() {
      this.bjTexts.push(this.SelectionText)
      console.log(this.bjTexts)
      this.$forceUpdate()
    },
    sizeChange(e) {
      console.log(e)
      let size = e.replace('px', '')
      this.rtTextSize = (size / 3).toFixed(0) + 'px'
    },
    weightChange(e) {
      if (e.target.checked) {
        this.fontWeight = 700
      } else {
        this.fontWeight = 400
      }
    },
    handleCancel() {
      this.visible = false
      this.SelectionText = ''
    },
    zyChange(e) {
      if (e.target.checked) {
        this.zyText = document
          .getElementById('add-content')
          .innerText.split('\n')
          .map(item => {
            return item.split('').map(i => {
              return {
                text: i,
                zy: ''
              }
            })
          })
      }

      this.isZy = e.target.checked
    },
    fontStyleChange(e) {
      if (e.target.checked) {
        this.fontStyle = 'italic'
      } else {
        this.fontStyle = 'normal'
      }
    },
    handleChange() {
      this.fontFamily = this.fontList.find(item => item.id == this.fontId).fontFamily
    },
    loadData() {
      getAction('/fonts/list', { current: '1', size: '-1', pageSize: '1000', pageNo: '1', fieId: 'fontFamily' }).then(
        res => {
          if (res.success) {
            this.fontList = res.data.records
/*             this.fontList.forEach(item => {
              item.fontFamily = 'font' + item.fontFamily
            }) */
            batchStyleInsertHeader(this.fontList || res.data)
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
          this.loading = false
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.show {
  border: 1px solid red;
  padding: 10px;
  width: 100%;
  height:100%;
  /*   -webkit-text-emphasis: "●";
  -webkit-text-emphasis-position: under; */
}
</style>
