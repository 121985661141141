var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mytasks_iframe_wrap", staticStyle: { padding: "10px" } },
    [
      _c(
        "a-spin",
        { staticStyle: { padding: "10px" }, attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-dropdown",
            { attrs: { trigger: ["contextmenu"] } },
            [
              _c(
                "vue-draggable-resizable",
                { staticStyle: { "z-index": "998" } },
                [
                  _vm.isZy
                    ? _c(
                        "div",
                        {
                          staticClass: "show",
                          style:
                            `font-size:${_vm.fontSzie};` +
                            `font-family:${_vm.fontFamily};` +
                            `line-height:${_vm.lineHeight};` +
                            `letter-spacing:${_vm.letterSpacing};` +
                            `text-align:${_vm.textAlign};` +
                            `font-style:${_vm.fontStyle};` +
                            `font-weight:${_vm.fontWeight};` +
                            `writing-mode:${_vm.fontMode};` +
                            `text-orientation:${
                              _vm.fontMode == "horizontal-tb"
                                ? "initial"
                                : "upright"
                            };` +
                            `transform:scale(${_vm.scaleWidth},${_vm.scaleHeight});` +
                            `-webkit-text-stroke:${
                              _vm.storkeSize + "px " + _vm.storkeColor
                            }`,
                          attrs: { contenteditable: !_vm.isZy },
                        },
                        _vm._l(_vm.zyText, function (item, idx) {
                          return _c("div", { key: idx }, [
                            _c(
                              "ruby",
                              [
                                _vm._l(item, function (i, index) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(i.text) +
                                        "\n                "
                                    ),
                                    _c(
                                      "rt",
                                      {
                                        key: index,
                                        style: `text-align: ${_vm.rtTextAlign};color: ${_vm.rtTextColor};font-size: ${_vm.rtTextSize};`,
                                      },
                                      [_vm._v(_vm._s(i.zy))]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  idx == _vm.text.split("\n").length - 1
                                    ? ""
                                    : "\n"
                                ) +
                                "\n          "
                            ),
                          ])
                        }),
                        0
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "show",
                          style:
                            `font-size:${_vm.fontSzie};` +
                            `font-family:${_vm.fontFamily};` +
                            `line-height:${_vm.lineHeight};` +
                            `letter-spacing:${_vm.letterSpacing};` +
                            `text-align:${_vm.textAlign};` +
                            `font-style:${_vm.fontStyle};` +
                            `font-weight:${_vm.fontWeight};` +
                            `writing-mode:${_vm.fontMode};` +
                            `text-orientation:${
                              _vm.fontMode == "horizontal-tb"
                                ? "initial"
                                : "upright"
                            };` +
                            `transform:scale(${_vm.scaleWidth},${_vm.scaleHeight});` +
                            `-webkit-text-stroke:${
                              _vm.storkeSize + "px " + _vm.storkeColor
                            }`,
                          attrs: {
                            contenteditable: !_vm.isZy,
                            id: "add-content",
                          },
                          on: { contextmenu: _vm.textClick },
                        },
                        [
                          _vm.bjTexts.length && _vm.bjTexts[0]
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.text.substr(
                                        0,
                                        _vm.text.indexOf(_vm.bjTexts[0])
                                      )
                                    ) +
                                    "\n            "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "-webkit-text-emphasis": '"●"',
                                      "-webkit-text-emphasis-position": "under",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.bjTexts[0]))]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.text.substr(
                                        _vm.text.indexOf(_vm.bjTexts[0]) +
                                          _vm.bjTexts[0].length
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _c("span", [_vm._v(_vm._s(_vm.text))]),
                        ]
                      ),
                ]
              ),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c("a-menu-item", { on: { click: _vm.bjClick } }, [
                    _vm._v("\n          标记\n        "),
                  ]),
                  _c("a-menu-item", { on: { click: _vm.zyClick } }, [
                    _vm._v("\n          注音\n        "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px", "line-height": "60px" } },
            [
              _c(
                "span",
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.fontMode,
                        callback: function ($$v) {
                          _vm.fontMode = $$v
                        },
                        expression: "fontMode",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "horizontal-tb" } }, [
                        _vm._v("\n            横向\n          "),
                      ]),
                      _c("a-radio", { attrs: { value: "vertical-rl" } }, [
                        _vm._v("\n            竖向从右到左\n          "),
                      ]),
                      _c("a-radio", { attrs: { value: "vertical-lr" } }, [
                        _vm._v("\n            竖向从左到右\n          "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        字体\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.fontId,
                        callback: function ($$v) {
                          _vm.fontId = $$v
                        },
                        expression: "fontId",
                      },
                    },
                    _vm._l(_vm.fontList, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.displayName) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        字号\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      on: { change: _vm.sizeChange },
                      model: {
                        value: _vm.fontSzie,
                        callback: function ($$v) {
                          _vm.fontSzie = $$v
                        },
                        expression: "fontSzie",
                      },
                    },
                    _vm._l(_vm.fontSizes, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        行间距\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.lineHeight,
                        callback: function ($$v) {
                          _vm.lineHeight = $$v
                        },
                        expression: "lineHeight",
                      },
                    },
                    _vm._l(_vm.lineHeights, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        字间距\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.letterSpacing,
                        callback: function ($$v) {
                          _vm.letterSpacing = $$v
                        },
                        expression: "letterSpacing",
                      },
                    },
                    _vm._l(_vm.letterSpacings, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        字体宽度\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.scaleWidth,
                        callback: function ($$v) {
                          _vm.scaleWidth = $$v
                        },
                        expression: "scaleWidth",
                      },
                    },
                    _vm._l(_vm.scales, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        字体高度\n        "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.scaleHeight,
                        callback: function ($$v) {
                          _vm.scaleHeight = $$v
                        },
                        expression: "scaleHeight",
                      },
                    },
                    _vm._l(_vm.scales, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _vm._v("\n        对齐方式\n        "),
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.textAlign,
                        callback: function ($$v) {
                          _vm.textAlign = $$v
                        },
                        expression: "textAlign",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "left" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.fontMode == "horizontal-tb"
                                ? "左对齐"
                                : "上对齐"
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("a-radio", { attrs: { value: "center" } }, [
                        _vm._v("\n            居中\n          "),
                      ]),
                      _c("a-radio", { attrs: { value: "right" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.fontMode == "horizontal-tb"
                                ? "右对齐"
                                : "下对齐"
                            ) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c("a-checkbox", { on: { change: _vm.weightChange } }, [
                    _vm._v("\n          加粗\n        "),
                  ]),
                  _c("a-checkbox", { on: { change: _vm.fontStyleChange } }, [
                    _vm._v("\n          斜体\n        "),
                  ]),
                  _c(
                    "a-checkbox",
                    {
                      attrs: { checked: _vm.isZy },
                      on: { change: _vm.zyChange },
                    },
                    [_vm._v("\n          是否注音\n        ")]
                  ),
                ],
                1
              ),
              _vm.isZy
                ? _c(
                    "span",
                    [
                      _vm._v("\n        注音对齐方式\n        "),
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.rtTextAlign,
                            callback: function ($$v) {
                              _vm.rtTextAlign = $$v
                            },
                            expression: "rtTextAlign",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "left" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.fontMode == "horizontal-tb"
                                    ? "左对齐"
                                    : "上对齐"
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("a-radio", { attrs: { value: "center" } }, [
                            _vm._v("\n            居中\n          "),
                          ]),
                          _c("a-radio", { attrs: { value: "right" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.fontMode == "horizontal-tb"
                                    ? "右对齐"
                                    : "下对齐"
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _vm._v("\n        字体描边\n        "),
                  _c("colorPicker", {
                    model: {
                      value: _vm.storkeColor,
                      callback: function ($$v) {
                        _vm.storkeColor = $$v
                      },
                      expression: "storkeColor",
                    },
                  }),
                  _c("a-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: { min: 0 },
                    model: {
                      value: _vm.storkeSize,
                      callback: function ($$v) {
                        _vm.storkeSize = $$v
                      },
                      expression: "storkeSize",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          staticClass: "question-modal",
          attrs: { visible: _vm.visible, footer: null, closable: false },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("a-input-search", {
            staticStyle: { width: "100%" },
            attrs: { allowClear: "", placeholder: "回车保存" },
            on: { pressEnter: _vm.addZy },
            model: {
              value: _vm.addZyText,
              callback: function ($$v) {
                _vm.addZyText = $$v
              },
              expression: "addZyText",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }